const lipSync = {
  mounted () {
    this.lipSyncConnection = null
    this.player = null
    this.lipSyncPageId = 0
    this.maxLatency = 0
  },
  methods: {
    startLipSync (player) {
      this.player = player
      this.isAudio = player.nodeName === 'AUDIO'
      console.log('lipsync player:', this.player)
      const maxLipsyncLatency = this.$xp.settings.mergedSettings.maxLipsyncLatency
      this.maxLatency = this.isAudio ? maxLipsyncLatency.audio : maxLipsyncLatency.video
      console.log('lipsync max latency: ' + this.maxLatency)
      this.lipSyncPageId = parseInt(this.$route.params.id)
      this.retryConnect = 0
      this.connectLipSyncServer()
    },
    stopLipSync () {
      if (this.lipSyncConnection) {
        this.lipSyncConnection.close()
      }
    },
    connectLipSyncServer () {
      this.ntpOffset = 9999999999
      this.ntpCount = 0
      this.deltaPlayPos = 0
      this.seekLatency = this.isAudio ? 230 : 1200 // preset value
      this.seekLatencyHistory = [this.seekLatency]
      this.retryConnect++

      let url = this.lipSyncUrl
      if (url.match(/<servername>/)) {
        const baseUrl = this.$xp.settings.baseUrl
        const matches = (/^http.?:\/\/([^/]+)\/.*$/).exec(baseUrl)
        if (matches) {
          url = url.replace(/<servername>/, matches[1])
        }
      }
      this.lipSyncConnection = new WebSocket(url)
      this.syncing = true
      this.seekLatencyIgnoreFirst = true
      this.lipSyncConnection.onopen = (e) => {
        this.onConnectionOpen(e)
      }
      this.lipSyncConnection.onerror = (e) => {
        this.onConnectionError(e)
      }
      this.lipSyncConnection.onmessage = (e) => {
        this.onConnectionMessage(e)
      }
      this.lipSyncConnection.onclose = (e) => {
        this.onConnectionClose(e)
      }
    },
    onConnectionOpen (error) {
      console.log(error)
      this.sendNtpReq()
    },
    onConnectionError (error) {
      console.log(error)
      if (this.lipSyncConnection) {
        this.lipSyncConnection.close()
        this.lipSyncConnection = null
      }

      if (this.retryConnect < 10) {
        setTimeout(() => { this.connectLipSyncServer() }, 1000)
      }
    },
    sendNtpReq () {
      const data = {
        Type: 'NTP',
        Req: Date.now(),
        PageID: parseInt(this.$route.params.id)
      }
      this.lipSyncConnection.send(JSON.stringify(data))
    },
    onConnectionMessage (e) {
      let data = null
      try {
        data = JSON.parse(e.data)
      } catch (e) {
        console.log(e)
      }
      if (data) {
        switch (data.Type) {
          case 'NTP':
            this.onNtpMessage(data)
            break
          case 'POS':
            if (!this.syncing) {
              this.onPosMessage(data)
            }
            break
        }
      }
    },
    onNtpMessage (data) {
      // check if this is the right page for the current video
      const currentPageId = parseInt(this.$route.params.id)
      if (currentPageId !== this.lipSyncPageId) {
        this.stopLipSync()
      } else if (data.PageID !== null && data.PageID !== this.lipSyncPageId) {
        console.log('lipsync: redirect to page ' + data.PageID)
        this.stopLipSync()
        this.$router.replace({ path: '/page/' + data.PageID, query: { replace: true } })
      }

      const now = Date.now()
      const offset = Math.round((now - data.Timestamp) + (now - data.Req) / 2)
      this.ntpCount++
      if (offset < this.ntpOffset) {
        this.ntpOffset = offset
        console.log('ntpOffset=' + this.ntpOffset)
      }
      if (this.ntpCount < 10) {
        this.sendNtpReq()
      } else {
        this.syncing = false
      }
    },
    onPosMessage (data) {
      // check if this is the right page for the current video
      const currentPageId = parseInt(this.$route.params.id)
      if (currentPageId !== this.lipSyncPageId) {
        this.stopLipSync()
      } else if (data.PageID !== null && data.PageID !== this.lipSyncPageId) {
        this.$router.replace({ path: '/page/' + data.PageID, query: { replace: true } })
      }

      if (!this.ready) {
        if (this.autoPlay) {
          // if (this.video) { this.video.play() } else if (this.sound) { this.sound.play() }
          this.player.play()
        }
        this.ready = true
      }
      const now = Date.now() - this.ntpOffset
      const currentDelta = now - (this.player.currentTime * 1000)
      this.deltaPlayPos = data.DeltaPlayPos

      if (this.player.paused || Math.abs(currentDelta - this.deltaPlayPos) > this.maxLatency) {
        this.player.currentTime = (now - this.deltaPlayPos + this.seekLatency) / 1000

        setTimeout(() => {
          const now = Date.now() - this.ntpOffset
          const currentDelta = now - (this.player.currentTime * 1000)
          const seekLatency = currentDelta - this.deltaPlayPos
          this.setSeekLatency(seekLatency)
        }, 400)
        console.log('SET DD:' + (currentDelta - this.deltaPlayPos) + ', deltaPlayPos:' + this.deltaPlayPos + ', currentDelta:' + currentDelta + ', latency:' + (currentDelta - this.deltaPlayPos))
      }
    },
    onConnectionClose () {
      this.retryConnect = 0
      this.player.pause()
    },
    setSeekLatency (newValue) {
      if (this.seekLatencyIgnoreFirst) {
        this.seekLatencyIgnoreFirst = false
      } else {
        if (Math.abs(newValue) < 500) {
          const seekLatency = Math.floor(newValue + this.seekLatency)
          this.seekLatencyHistory.push(seekLatency)
          if (this.seekLatencyHistory.length > 5) this.seekLatencyHistory.shift()
          let sum = 0
          let div = 0
          for (let i = 0; i < this.seekLatencyHistory.length; i++) {
            sum += this.seekLatencyHistory[i] * (i + 1)
            div += i + 1
          }
          console.log('seekLatencyHistory:' + this.seekLatencyHistory)
          this.seekLatency = Math.floor(sum / div)
          console.log('correct seekLatency(' + newValue + ') => ' + this.seekLatency)
        } else {
          console.warn('seekLatency:' + (this.seekLatency + newValue))
        }
      }
    }
  }
}

export default lipSync
